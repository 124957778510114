import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import AniLink from "gatsby-plugin-transition-link/AniLink"

class IndexPage extends React.Component {
  render() {

    return (
      <Layout location={this.props.location}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <Masthead>
          <img style={{ margin: 0 }} src="./topper-golf.png" alt="Toppergolf" />
        </Masthead>
        <h1>
          Welcome to the new Toppergolf website!
        </h1>
        <p>
          We give new and upcoming golf brands the chance to introduce their exciting new products.
        </p>
        <p>
          This is our Clubhouse and we will be sharing our Topper tales.
        </p>
        <p>
          If you have a product or story you would like to share please get in touch.
        </p>
        <AniLink paintDrip to="/blog/" hex="#eb340c">
          <Button marginTop="35px">Go to Blog</Button>
        </AniLink>
      </Layout>
    )
  }
}

const Masthead = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background: url(./background.jpg) no-repeat center center scroll;
  border-radius: 50%;
`

export default IndexPage
